import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes formations';
export const titleAlveole = 'Mes tutoriels';
export const txtNbTuto = 'tutoriel';
export const txtNbTutoPluriel = 'tutoriels';

/************************ DeV ******************************************************/
export const zAPIdocuments = "https://api-mestutoriels-dev.cristal-hub.fr";
export const zUrlBack = "https://api-mestutoriels-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const zAPICategoryTutoriel = "https://api-mestutoriels-dev.cristal-hub.fr/api/category_tutoriels";
export const zAPITutoriels = "https://api-mestutoriels-dev.cristal-hub.fr/api/tutoriels";
/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000";
// export const zUrlBackNotifications = "https://localhost:8001";

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";


export const CristalContainerHeaderTitle = styled(Paper)(({theme}) => ({
    backgroundColor: 'unset',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    padding: theme.spacing(1),
}));

export const CristalTextMessage = styled(TextField)(({theme}) => ({
    '& label': {
        fontSize: '16px',
        color: theme.palette.mode === 'light' ? '#00508F!important' : '#ffffff!important',
        fontFamily: 'PoppinsRegular',
    },
    '& input': {
        paddingRight: '48px', // Ajustement pour le label
    },
    '& textarea': {
        fontFamily: 'PoppinsRegular',
    },
    '& fieldset': {
        fontSize: '16px',
        borderColor: theme.palette.mode === 'light' ? '#00508F' : '#ffffff',
        borderRadius: '10px',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: '16px'
    }
}));
